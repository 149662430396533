//还款方式 选择框
<template>
  <div class="repaymentPlaItem" v-bind="$attrs" v-on="$listeners">
    <base-select
     v-bind="$attrs"
      v-on="$listeners"
      placeholder="请选择"
      :options="options"
      :selectedField="['dictId', 'dictName']"
      class="showBtn"
      @change="changeRepaymentPlaItem"
    ></base-select>
    <!-- :class="{ showBtn: $attrs.value == 1 }" -->
      <!-- <el-tooltip  effect="dark"  :enterable="false" :content="'设置还款计划'" placement="top" v-if="$attrs.value === '1'" :hide-after='3000'>
      <span class="set iconfont iconshezhi" @click="visible = true" v-if="$attrs.value === '1'"></span>
      </el-tooltip> -->
      <el-tooltip  effect="dark"  :enterable="false" :content="'设置还款计划'" placement="top"  :hide-after='3000'>
      <span class="set iconfont iconshezhi" @click="visible = true" ></span>
      </el-tooltip>
    <!-- 还款登记弹窗 -->
    <base-dialog
      :visible="visible"
      width="1200px"
      :showFooter="false"
       :before-close="close"
    >
    <div slot="title" >
      <div class="customTitle"><span class="customTitleIcon"> * </span>还款计划</div>
    </div>
      <div class="add">
        <div class="addbutton" @click="addPlanItem">
          <i class="el-icon-plus"></i>
        </div>
      </div>
      <!-- 手动设置还款计划表格 -->
      <base-table
        :columns="repaymentPlanColumn"
        :tableAttrs="{
          data: repaymentPlanData,
          stripe: true,
        }"
        ref="tableData"
        :isCaculateHeight="false"
        :showPage="false"
      >
        <template slot="code" slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
        <!-- 日期 -->
        <template slot="preRepayDate" slot-scope="scope">
          <el-date-picker
            v-model="scope.row.preRepayDate"
            type="date"
            placeholder="选择日期"
            pickerOptions:startDatePicker
            value-format='timestamp'
            @change="changeDate(scope.row.preRepayDate, scope.$index)"
          >
          </el-date-picker>
        </template>
        <!-- 还款金额 -->
        <template slot="preRepayAmount" slot-scope="scope">
          <amount-input
            v-model="scope.row.preRepayAmount"
            type="Y"
            placeholder="请输入"
          />
        </template>
        <!-- 还款合同 -->
        <template slot="repayContractIdList" slot-scope="scope">
          <base-select
            v-model="scope.row.repayContractIdList"
            :clearable="true"
            :multiple="true"
            :disabled="!scope.row.preRepayDate"
            :options="contactListOptions"
            :selectedField="['finContractId', 'repayContractName']"
            @change="changeContract(scope.row.repayContractIdList, scope.$index)"
          ></base-select>
        </template>
        <!-- 还款条件 -->
        <template slot="repayCondition" slot-scope="scope">
          <el-input
           v-model="scope.row.repayCondition"
           maxlength="500"
          ></el-input>
        </template>
        <template slot="action" slot-scope="scope">
          <icon-button
            content="删除"
            icon="iconfont iconshanchu1"
            @click="delPlan(scope.row,scope.$index)"
          />
        </template>
      </base-table>
      <template slot="footer">
        <base-button label="提 交" @click="submit"></base-button>
        <base-button
          label="关 闭"
          type="default"
          @click="close"
        ></base-button>
      </template>
    </base-dialog>
  </div>
</template>
<script>
// import BaseInput from '@/components/input/sz-input/sz-input.vue'
import AmountInput from '@/components/input/amount-input/amount-input.vue'
import BaseDialog from '@/components/common/base-dialog/base-dialog.vue'
import BaseSelect from '@/components/common/base-select/base-select.vue'
import baseTable from '@/components/common/table/base-table/base-table.vue'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import baseButton from '@/components/common/button/base-button/base-button.vue'
import { getDictLists } from '@/filters/fromDict'
import { repaymentPlanTable } from '../utils/closed-loop-info-config'
// import { contactApi } from '@/api/contactApi'
import { formatDate } from '@/utils/auth/common'
import { contactApi } from '@/api/contactApi'

export default {
  props: {
    // contactListOptions: Array
  },
  components: {
    BaseDialog,
    IconButton,
    BaseSelect,
    baseButton,
    baseTable,
    AmountInput

  },
  data () {
    return {
      oldData: [],
      visible: false,
      curSelected: '', // 选中的值
      repaymentPlanData: [{}], // 还款计划表数据,
      startDatePicker: {},
      contactListOptions: [] // 合同选择组
    }
  },
  computed: {
    options () {
      return getDictLists('REPAY_TYPE_STATUS')
    },
    repaymentPlanColumn () {
      return repaymentPlanTable(this)
    }

  },
  created () {
    this.businessId = this.$route.query.businessId
    this.startDatePicker = {
      disabledDate (time) {
        const disabledTime = new Date('1999-12-31')
        return time.getTime() <= disabledTime
      }
    }
  },
  mounted () {
    if (this.businessId) {
      this.getContactlList()
    }
  },
  watch: {
    visible (val) {
      if (val) {
        console.log(this.$attrs.formData, 'this.$attrs.formData')
        this.getContactlList()
        if (this.$attrs.formData && this.$attrs.formData.length > 0) {
          this.repaymentPlanData = this.$attrs.formData
          this.oldData = JSON.parse(JSON.stringify(this.$attrs.formData))
        } else {
          this.repaymentPlanData = [{}]
          this.oldData = [{}]
        }
      }
    }
  },
  methods: {
    // 改变选项清空数据
    changeRepaymentPlaItem () {
      this.$emit('changeRepayment', '')
    },
    // 点击提交，传备份老数据
    close () {
      this.$emit('closedialog', this.oldData)
      this.visible = false
    },
    // 点击提交，把数据加到外部对象上
    submit () {
      let pass = true
      this.repaymentPlanData.forEach(item => {
        if (!item.preRepayDate || !item.preRepayAmount || !item.repayContractIdList || !item.repayCondition) {
          pass = false
        }
      })
      console.log(pass, 'pass')
      if (!pass) {
        return this.warning('请完善表内所有数据')
      }
      this.$emit('submitdialog', this.repaymentPlanData)
      this.visible = false
    },
    change (data) {},
    // 删除计划
    delPlan (data, index) {
      if (this.repaymentPlanData.length === 1) {
        return this.warning('仅有一条数据的情况不能删除')
      }
      this.repaymentPlanData.splice(index, 1)
    },
    // 增加计划
    addPlanItem () {
      console.log(this.$attrs.value, 'this.$attrs.value')
      if ((this.$attrs.value === '0' || this.$attrs.value === '2') && this.repaymentPlanData.length > 0) {
        return this.warning('还款条件为: 一次性还款,能且仅能编辑一条数据，不可删除')
      }
      this.repaymentPlanData.push({})
    },
    // 获取还款计划合同列表
    getContactlList () {
      contactApi
        .getContactlList({ businessId: this.businessId, finContractType: 0 })
        .then((res) => {
          console.log(res.data, 'asdasdasd')
          if (res.data.length > 0) {
            this.contactListOptions = res.data.map((item) => {
              return {
                repayContractName: item.finContractName,
                finContractId: item.finContractId
              }
            })
          }
        })
    },
    // 选择合同自动拼接
    changeContract (data, index) {
      console.log(data, '++==')
      let value = ''
      const repaymentPlanContractArr = []
      const repayContractNameArr = []
      data.forEach((elem) => {
        // elem 合同id
        this.contactListOptions.forEach((item) => {
          if (item.finContractId === elem) {
            if (repaymentPlanContractArr.length === 0) {
              repayContractNameArr.push(item.repayContractName)
              repaymentPlanContractArr.push(
                this.repaymentPlanData[index].isDate
                  ? this.repaymentPlanData[index].preRepayDate +
                      '/' +
                      item.repayContractName
                  : formatDate(
                    this.repaymentPlanData[index].preRepayDate,
                    'YY/MM/DD'
                  ) +
                      '/' +
                      item.repayContractName
              )
            } else {
              repaymentPlanContractArr.push(item.repayContractName)
            }
          }
        })
      })

      value = repaymentPlanContractArr.join(',')
      this.$set(this.repaymentPlanData[index], 'repayContractName', repayContractNameArr.join(','))
      this.$set(
        this.repaymentPlanData[index],
        'repayContractId',
        data.join(',')
      )
      this.$set(this.repaymentPlanData[index], 'repayCondition', value)
    },
    // 选择时间
    changeDate (data, index) {
      console.log(data, index)
      if (data) {
        this.$set(
          this.repaymentPlanData[index],
          'repayCondition',
          formatDate(this.repaymentPlanData[index].preRepayDate, 'YY/MM/DD') +
            '/'
        )
      } else {
        this.$set(this.repaymentPlanData[index], 'repayCondition', '')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .el-input__validateIcon {
  display: none;
}
.repaymentPlaItem {
  display: flex;
}
.add {
  padding-top: 20px;
  padding-bottom: 20px;
  height: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .addbutton {
    margin-left: 20px;
    background: #4A7CF0;
    width: 20px;
    height: 20px;
    color: #fff;
    text-align: center;
    line-height: 20px;
    cursor: pointer;
    &:hover {
      background-color: #6996FF;
    }
  }
}
// /deep/ .el-select {
//   width: 240px !important;
// }
.showBtn {
  width: calc(100% - 50px) !important;
}
.set {
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
  width: 34px;
  height: 34px;
  text-align: center;
  line-height: 34px;
  display: inline-block;
  background: linear-gradient(90deg, #285ED7 0%, #749EFF 100%);
  border-radius: 50%;
}
/deep/ .el-select__tags {
  overflow-x: hidden;
}
.customTitle{
  font-size: 18px;
  color: #fff;
  .customTitleIcon{
    font-size: 20px;
    color: red;
  }
}
</style>
